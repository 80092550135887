// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
$enable-shadows: true;
//$enable-gradients: true;

// Changing the body background and text
//$body-bg: #d3e9eb;
//$body-color: #151417;

// Changing the border radius of buttons
//$border-radius: 15px;

// Changing the theme colors
$dark: #13294B;
$primary: #FF5E00;
$secondary: #2DCCD3;
$success: $dark;
//$info: #495dff;
//$warning: #ef8143;
//$danger: #ff293a;
//$light: #dfe6ee;



// Fonts
@import url('https://fonts.googleapis.com/css?family=Noto+Sans|Open+Sans&display=swap');

// stylelint-disable value-keyword-case
$font-family-sans-serif:  "Noto Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:  SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
//$theme-colors: (
//  "cool": #4d3fa3
//);