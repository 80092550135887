// Rest Details

.rest-details-page {

  padding-bottom: 80px;

  .navigation-buttons {
    display: none;
    margin-top: 40px;
  }

  .mobile-restdetails-button {
    position: fixed;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    .row {
      background: $dark;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .graze-details-container {

    word-break: break-word;

    .nav {
      &.nav-tabs {
        a {
          color: $dark;
          &.active {
            font-weight: bold;
          }
        }
      }
    }

    a {
      color: $dark;
    }

    .graze-restdetails-row {
      margin-bottom: 10px;
    }

    .restdetails-hours {
      a {
        color: white;
      }
    }

    .more-places-title {
      margin-bottom: 10px;
      margin-top: 20px;
      border-bottom: 1px solid #dee2e6;
      font-weight: bold;
    }

    .tab-content {
      padding-top: 10px;
    }

    // Map
    #map iframe {
        max-width: 100%;
    }

    // Google Photos
    .google-photos {
      padding-top: 10px;
    }

    .goolge-photo img {
      width: 200px;
      height: auto;
      margin-bottom: 10px;
    }

  }

}

@include media-breakpoint-up(sm) {
  .rest-details-page {
    padding-bottom: 0;
    .navigation-buttons {
      display: block;
    }
  }
  .mobile-restdetails-button {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .rest-details-page {
    .graze-details-container {
      .goolge-photo img {
        width: 100%;
      }
    }
  }
}