/***************************
* Terms of Use
***************************/

#grz-tos {
    background-color: #F5F5F5;
    border: 1px solid #DDDDDD;
    border-radius: 4px 0 4px 0;
    color: #3B3C3E;
    left: -1px;
    padding: 10px 7px 5px;
    margin-bottom: 1vh;
}

.grzPublished {
    text-align: justify;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid hsla(208,7%,46%,.2);
    .publishedTitle {
        margin-bottom: 1rem;
    }

}

#loginAgain {
    margin-bottom: 1rem;
}


.termsofuse {

    /*
    height: 50vh;
    overflow: scroll;
    border: 1px solid $dark;
    padding: 1vw;
    margin-bottom: 2vw;
    */

    height: 30vh;
    overflow:scroll;
    overflow-x:hidden;

    p, li {
        font-size: .7rem;
    }

    .tou-title {
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .tou-subtitle {
        font-weight: bold;
        font-size: 1rem;
    }

    .tou-subtext {
        text-align: center;
    }

    .tou-bold {
        font-weight: bold;
    }

    ol {
        padding-left: 12px;

        &.tou-alphalist {
            list-style-type: lower-alpha;
        }

        &.tou-lowerromanlist {
            list-style-type: lower-roman;
        }

        &.tou-noliststyle {
            list-style-type: none;
        }

        li {
            margin-bottom: 1vh;
        }
    }

    .tou-italic {
        font-style: italic;
    }

    .tou-underline {
        text-decoration: underline;
    }
}

@include media-breakpoint-down(md) {
    .termsofuse {
        .tou-title {
            font-size: 20px;
        }

        .tou-subtitle {
            font-size: 14px;
        }
    }

    #grz_accept_tos {
        font-size: 11px;
    }

    .websiteAdminAddress, .websiteAddress {
        text-align: center !important;
    }
}
