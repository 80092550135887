// Password Recovery

.validPassword, .validConfirmPassword, .validEmail, .validHint {
    margin-top: 8px;
    color: green;
}

#grazeAccountPasswdRecoveryForm {
  .sendHintButton {
    margin-bottom: 10px;
  }

  .resetPwdButton {
    margin-bottom: 10px;
  }

}