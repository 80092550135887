// Utils
.error {
    color: red;
}

.success {
    color: green;
}

button, #lightbox {
    cursor: pointer;
}

.validPassword, .validConfirmPassword, .validEmail, .validHint {
    margin-top: 8px;
    color: green;
}

// Loader
#graze-loading {
    height: 100%;
    width: 100%;
    z-index: 9999;
    position: absolute;
    background: rgba(51, 51, 51, 0.94);
    text-align: center;
    padding-top: 15%;
    color: white;
}

// Branding
.graze-club-branding {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 20px;
    width: 200px;
    height: auto;
    img {
        width: 100%;
        height: auto;
    }
}

.graze-club-branding-dispCreateAccount,
.graze-club-branding-dispPasswdRecovery,
.graze-club-branding-dispPasswdReset,
.graze-club-branding-createAccountConfirm {
  display: none !important;
}

.login-logo {
    width: 200px;
    height: auto;
    margin: $grid-gutter-width;
}

@include media-breakpoint-down(sm) {
    .graze-club-branding {
        width: 100%;
        position: initial !important;
        text-align: center;
        img {
            width: 150px;
        }
    }

    .graze-club-branding-dispRestSearch, .graze-club-branding-dispPasswdRecovery {
        margin-top: 20px;
        position: fixed;
    }

    .login-logo {
        width: 150px;
        height: auto;
        margin: $grid-gutter-width;
    }
}

@include media-breakpoint-down(md) {
    .progress-bar .complete-text {
        display: none;
    }
}

// Progress Meter

.progress {
    margin-bottom: 20px;
    height: 1.2rem;
}

.progressMeter-container {
    display: none;
    width: 100%;
    margin-top: 20px;
}

.progress-meter {
    padding: 0;
}

ol.progress-meter {
    padding-bottom: 0px;
    margin-bottom: 0px;
    list-style-type: none;
    text-align: justify;
}

ol.progress-meter::after {
    width: 100%;
    display: inline-block;
    content: ".";
    visibility: hidden
}

ol.progress-meter li {
    display: inline-block;
    text-align: center;
    text-indent: -19px;
    height: 38px;
    width: 20%;
    font-size: 12px;
    border-bottom-width: 4px;
    border-bottom-style: solid;

    a {
        font-weight: 700;
    }
}

ol.progress-meter li:before {
    position: relative;
    float: left;
    text-indent: 0;
    left: -webkit-calc(50% - 9.5px);
    left: -moz-calc(50% - 9.5px);
    left: -ms-calc(50% - 9.5px);
    left: -o-calc(50% - 9.5px);
    left: calc(50% - 9.5px);
}

ol.progress-meter li.done {
    font-size: 12px;
    a {
        color: $dark;
    }
}

ol.progress-meter li.done:before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f058";
    height: 20px;
    width: 20px;
    line-height: 21.85px;
    bottom: -28.175px;
    border: none;
    border-radius: 19px;
}

ol.progress-meter li.todo {
    font-size: 12px;
    font-weight: 700;
}

ol.progress-meter li.todo:before {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f111";
    font-size: 18px;
    bottom: -30px;
    line-height: 18.05px;
}

ol.progress-meter li.done {
    color: black;
    border-bottom-color: $secondary;
    float: left;
}

ol.progress-meter li.done:before {
    color: white;
    background-color: $secondary;
}

ol.progress-meter li.todo {
    color: silver;
    border-bottom-color: silver;
    float: left;
}

ol.progress-meter li.todo:before {
    color: silver;
}

ol.progress-meter li.progress-active a {
    color: $dark;
}

ol.progress-meter li.progress-active {
    color: $dark;
    border-bottom-color: $dark;
    float: left;
}

ol.progress-meter li.progress-active:before {
    color: $dark;
}

// Lightbox
#lightbox {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(0,0,0,.7);
    text-align:center;
    z-index: 9999;
}

#lightbox p {
    text-align:right;
    color:#fff;
    margin-right:20px;
    font-size:12px;
}

#lightbox img {
    box-shadow:0 0 25px #111;
    -webkit-box-shadow:0 0 25px #111;
    -moz-box-shadow:0 0 25px #111;
    max-width:100%;
    height: auto;
}
