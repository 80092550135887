// Search Results CSS

.search-result-page {
  padding-bottom: 80px;

  .restSelect .far {
    font-size: 1.3rem;
  }

  .navigation-buttons {
    display: none;
    margin-top: 10px;
  }

  .mobile-restdetails-button {
    position: fixed;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    .row {
      background: $dark;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  #table_data .searchResultItemHover {
      background: #92DD33;
      cursor: pointer;
      cursor: hand;
  }

  #table_data .selected {
      background: #ddd;
  }

  .table_header {
      font-weight: bolder;
      border-bottom-style: solid;
  }

  .column_header {
      font-weight: bold;
      border-bottom-style: solid;
      border-bottom-width: thin;
      font-family: 'Roboto', sans-serif;
  }

  .column_data {
      font-weight: normal;
      border-bottom-style: solid;
      border-bottom-width: thin;
  }
}

@include media-breakpoint-up(sm) {
  .search-result-page {
    padding-bottom: 0;
    .navigation-buttons {
      display: block;
    }
  }
  .mobile-restdetails-button {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  #table_data {
    font-size: .8rem;
  }
}